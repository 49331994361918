import { TEMPLATE_CACHE_MODULE } from 'common/config';

/////////////////////////////

angular.module(TEMPLATE_CACHE_MODULE, []);

/////////////////////////////
//                         //
//    Public attributes    //
//                         //
/////////////////////////////

const frontOfficeTemplates = require.context('./modules/', true, /\.html$/u);
frontOfficeTemplates.keys().forEach(function forEachModules(frontOfficeTemplate) {
    frontOfficeTemplates(frontOfficeTemplate);
});

/////////////////////////////

export { frontOfficeTemplates };
